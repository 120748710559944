import React from "react";
import { graphql } from "gatsby";
import Header from "Commons/Header";
import Footer from "Commons/Footer";
import InternalPage from "Commons/InternalPage";
import { Helmet } from "react-helmet";
import useLang from "Commons/Hooks/useLang";

const SecurityResearcherPage = ({ data }) => {
  const [lang] = useLang();
  const {
    securityResearcherPageTitle,
    securityResearcherTitle,
    securityResearcherDescription,
  } = data.allContentfulOpenings.nodes[lang];
  return (
    <>
      <Helmet>
        <title>{securityResearcherPageTitle}</title>
      </Helmet>
      <Header noScrollEffect />
      <InternalPage
        title={securityResearcherTitle}
        richText={securityResearcherDescription}
      />
      <Footer />
    </>
  );
};

export const query = graphql`
  query getSecurityResearcherTexts {
    allContentfulOpenings {
      nodes {
        securityResearcherTitle
        securityResearcherPageTitle
        securityResearcherDescription {
          raw
        }
      }
    }
  }
`;

export default SecurityResearcherPage;
